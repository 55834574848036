// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$primary-color: (
  50: #e4f2fe,
  100: #e7f3fe,
  200: #cfe8fc,
  300: #b6dcfb,
  400: #9ed1fa,
  500: #2194f3,
  600: #6eb9f7,
  700: #55aef6,
  800: #2196f3,
  900: #0d8bf2,
  A100: #ffffff,
  A200: #e1ecff,
  A400: #aeccff,
  A700: #95bcff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$secondary-color: (
  50: #eff3f5,
  100: #e0e6eb,
  200: #d0dae1,
  300: #d0dae1,
  400: #92a8b9,
  500: #7390a5,
  600: #465b6d,
  700: #3c4e5d,
  800: #303f4b,
  900: #28343e,
  A100: #8ecaff,
  A200: #5bb2ff,
  A400: #289aff,
  A700: #0e8eff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #ffffff,
  ),
);

$payments-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$payments-primary: mat.define-palette($primary-color);

// The warn palette is optional (defaults to red).
$payments-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$payments-theme: mat.define-light-theme(
  (
    color: (
      primary: $payments-primary,
      accent: $payments-accent,
      warn: $payments-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($payments-theme);

/* You can add global styles to this file, and also import other style files */
* {
  font-family: inherit;
  font-weight: normal;
  border: 0 none;
  outline: 0;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

#embeddedpayments {
  width: 100%;
  #embedded-form-container {
    width: 100%;
  }
}
